/* eslint-disable */
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useUserJiraAccountId } from '../../utility/UserUtility';
import { ToggleToastNotification } from '../../utility/NotificationUtils';
import { CreateTicket } from '../../methods/JiraMethods';
import CreateTicketEditor from '../../components/JiraTextEditor';
import MenuBar from '../../components/component_parts/JiraTextEditorMenuBar';
import { EditorContent, useEditor } from '@tiptap/react';

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Blockquote, BlockquoteOptions } from '@tiptap/extension-blockquote';
import { Bold, BoldOptions } from '@tiptap/extension-bold';
import { BulletList, BulletListOptions } from '@tiptap/extension-bullet-list';
import { Code, CodeOptions } from '@tiptap/extension-code';
import { CodeBlock, CodeBlockOptions } from '@tiptap/extension-code-block';
import { Document } from '@tiptap/extension-document';
import { Dropcursor, DropcursorOptions } from '@tiptap/extension-dropcursor';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import { HardBreak, HardBreakOptions } from '@tiptap/extension-hard-break';
import { Heading, HeadingOptions } from '@tiptap/extension-heading';
import { History, HistoryOptions } from '@tiptap/extension-history';
import { Italic, ItalicOptions } from '@tiptap/extension-italic';
import { ListItem, ListItemOptions } from '@tiptap/extension-list-item';
import { OrderedList, OrderedListOptions } from '@tiptap/extension-ordered-list';
import { Paragraph, ParagraphOptions } from '@tiptap/extension-paragraph';
import { Strike, StrikeOptions } from '@tiptap/extension-strike';
import { Text } from '@tiptap/extension-text';
/* eslint-enable @typescript-eslint/no-unused-vars */

/** Styles */
import 'remixicon/fonts/remixicon.css';
import '../../components/styles/_JiraTextEditor.css';
import '../../components/styles/JiraTextEditor.css';

const CreateTicketModal = ({
    show,
    handleClose,
    handleSave,
}) => {

    const jiraUserId = useUserJiraAccountId();

    const [hasError, setHasError] = useState(false);

    const [summary, setSummary] = useState();
    const [description, setDescription] = useState();

    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            Bold,
            Strike,
            Italic,
            Code,
            History,
            Heading.configure({
                levels: [1, 2, 3],
            }),
            CodeBlock,
            Blockquote,
            HardBreak,
            ListItem,
            BulletList,
            OrderedList,
        ],
        content: description,
        editable: true,
        injectCSS: false,
        onUpdate({ editor }) {
            setDescription(editor.getJSON());
        },
    });

    const handleCreateTicket = async () => {

        // console.log('event', summary, description);

        if (!summary || summary.length === 0) {
            setHasError([
                'summary'
            ]);

            return;
        }

        const response = await CreateTicket({
            reporter: jiraUserId,
            summary: summary,
            description: description,
        });

        if (response.ErroCode) {
            setHasError([
                'error'
            ]);

            return;
        }

        if (handleSave) {
            handleSave(response);
            return;
        }

        ToggleToastNotification({
            title: `Ticket ${response.key} creato`,
            position: 'bottom-start',
        });

        handleClose();
    };

    return (
        <Modal size='lg' show={show} onHide={handleClose}>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Spedisci un invito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Crea un ticket per la segnalazione di errori o problemi legati a questo portale
                    </p>
                    <Form.Group className='mb-3'>
                        <Form.Label>Sommario</Form.Label>
                        <Form.Control
                            type='text'
                            name='summary'
                            onChange={(event) => setSummary(event.target.value)}
                            required
                            invalid={hasError && hasError.includes('summary')}
                        />
                        <Form.Control.Feedback type='invalid'>
                            Per favore inserisci un titolo sommario a questo ticket
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>Descrizione</Form.Label>
                        <div className='border'>
                            {/* <MenuBar editor={editor} /> */}
                            <EditorContent editor={editor} />
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Annulla</Button>
                    <Button variant="primary" onClick={handleCreateTicket}>Crea ticket</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreateTicketModal;
