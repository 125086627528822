declare global {
    interface String {
        /**
         * Convert a `string` into a capitalized one.
         * If the param `entireString` is `true` will return the whole `string` capitalized, otherwise only the first letter
         * @param entireString Determine if the entire word need to be capitalized, as default is set to `false`
         */
        capitalize(entireString?: boolean): string;

        /**
         * Convert a give relative path into full path
         */
        getImageUrl(): string;
    }

    interface Number {
        /**
         * Convert a number from milliseconds to seconds
         */
        toSeconds(): number;

        /**
         * Convert a number from seconds to milliseconds
         */
        toMilliseconds(): number;
    }
}

String.prototype.capitalize = function (entireString: false): string {

    if (entireString) {
        return this.toUpperCase();
    }

    return `${this.slice(0, 1).toUpperCase()}${this.slice(1)}`;
};

String.prototype.getImageUrl = function (): string{

    if (!this) {
        return `${process.env.REACT_APP_PLACEHOLDER_MEDIA_URL}`;
    }

    return `${process.env.REACT_APP_MEDIA_URL}${this}`;
};

Number.prototype.toSeconds = function (): number {
    return <number>this / 1000;
};

Number.prototype.toMilliseconds = function (): number {
    return <number>this * 1000;
};

export { };

