import React from 'react';
import { Container } from 'react-bootstrap';
import Navbar from '../components/Navbar';

// Contexts
import { SettingsContextProvider } from '../contexts/SettingsContext';
import { UserProvider } from '../contexts/UserContext';

// Styles
import '../styles/Layout.css';

const Layout = ({
	children,
}) => {

	return (
		<UserProvider>
			<SettingsContextProvider>
				<Container fluid className='px-0 root-container'>
					<Navbar />
					<div className='h-100 main-container'>
						{children}
					</div>
				</Container>
			</SettingsContextProvider>
		</UserProvider>
	);
};

export default Layout;
