import React from 'react';

const MenuBar = ({
    editor,
}) => {

    if (!editor) {
        return null;
    }

    const disabled = true;

    return (
        <div className='menu-bar'>
            <button
                onClick={() => editor?.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run() || disabled}
                className={editor.isActive('bold') ? 'is-active' : ''}
            >
                <i className="ri-bold"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={!editor.can().chain().focus().toggleItalic().run() || disabled}
                className={editor.isActive('italic') ? 'is-active' : ''}
            >
                <i className="ri-italic"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={!editor.can().chain().focus().toggleStrike().run()}
                className={editor.isActive('strike') ? 'is-active' : ''}
            >
                <i className="ri-strikethrough-2"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().toggleCode().run()}
                disabled={!editor.can().chain().focus().toggleCode().run()}
                className={editor.isActive('code') ? 'is-active' : ''}
            >
                <i className="ri-code-line"></i>
            </button>
            {/* <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
                clear marks
                </button>
                <button onClick={() => editor.chain().focus().clearNodes().run()}>
                clear nodes
            </button> */}
            {/* <button
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={editor.isActive('paragraph') ? 'is-active' : ''}
            >
                <i className="ri-paragraph"></i>
            </button> */}
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
            >
                <i className="ri-h-1"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
            >
                <i className="ri-h-2"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
            >
                <i className="ri-h-3"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
                disabled={disabled}
                >
                <i className="ri-list-unordered"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
                disabled={disabled}
            >
                <i className="ri-list-ordered"></i>
            </button>
            {/* <button
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={editor.isActive('codeBlock') ? 'is-active' : ''}
            >
                <i className="ri-code-box-line"></i>
            </button> */}
            <button
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
                disabled={disabled}
            >
                <i className="ri-chat-quote-line"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().undo().run()}
                disabled={!editor.can().chain().focus().undo().run()}
            >
                <i className="ri-arrow-go-back-line"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().redo().run()}
                disabled={!editor.can().chain().focus().redo().run()}
            >
                <i className="ri-arrow-go-forward-line"></i>
            </button>
        </div>
    );
};

export default MenuBar;