import axios from 'axios';

import { SessionCookie } from '../contexts/SessionContext';
import { UserId } from '../contexts/UserContext';

const ValideCallTypes = [
	'get',
	'post',
	'put',
	'delete',
];

const getHeaders = (props) => {

	const headers = {
		'Content-Type': 'application/json',
		...props?.headers,
	};

	if (props.formData) {
		headers['Content-Type'] = 'multipart/form-data';
	}

	headers['Access-Control-Allow-Origin'] = '*';

	return headers;
};

const getRequest = async (props) => {
	const result = axios.get(props.path, {
		params: props.data,
		responseType: props.responseType ? props.responseType : 'application/json',
		headers: {
            ...props?.headers,
        },
	})
		.then(response => {
			if (props.log) {
				console.log(response.data);
			}

			return response.data;
		})
		.catch(err => {
			console.log(err);
		});

	return await result;
};

const postRequest = async (props) => {

	const userId = UserId();

	/* eslint-disable camelcase */
	if (!props.formData) {
		if (props?.data?.id) {
			props.data = {
				...props.data,
				updated_by: userId,
			};
		}
		else {
			props.data = {
				...props.data,
				created_by: userId,
			};
		}
	}
	/* eslint-enable camelcase */

	const result = axios.post(props.path, props.data, {
		headers: getHeaders(props),
	})
		.then(response => {
			if (props.log) {
				console.log(response.data);
			}

			return response.data;
		})
		.catch(err => {
			if (err.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.log(err.response.data);
				console.log(err.response.status);
				console.log(err.response.headers);
			} else if (err.request) {
				// The request was made but no response was received
				// `err.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log(err.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', err.message);
			}
			console.log(err.config);
		});

	return await result;
};

// TODO Need to be fixed, return a CORS error maybe the passed data is not in properly format.
const putRequest = async (props) => {
	const result = axios.put(props.path, props.data, {
		headers: getHeaders(props),
	})
		.then(response => {
			if (props.log) {
				console.log(response.data);
			}

			return response.data;
		})
		.catch(err => {
			if (err.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.log(err.response.data);
				console.log(err.response.status);
				console.log(err.response.headers);
			} else if (err.request) {
				// The request was made but no response was received
				// `err.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log(err.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', err.message);
			}
			console.log(err.config);
		});

	return await result;
};

const deleteRequest = async (props) => {
	const result = axios.delete(props.path, {
		params: props.data,
		responseType: props.responseType ? props.responseType : 'application/json',
	})
		.then(response => {
			if (props.log) {
				console.log(response.data);
			}

			return response.data;
		})
		.catch(err => {
			console.log(err);
		});

	return await result;
};

const RunQuery = async (props) => {

	// console.log('RunQuery', 'begin', props);

	if (!props.path) return Errors.PathNotProvided;
	if (!props.callType || !ValideCallTypes.includes(props.callType)) return Errors.CallTypeNotProvided;

	if (!props.formData) {
		/** Calls like Login don't need a session cookie */
		if (!props.withoutCookie) {
			props.data = {
				...props.data,
				sessionCookie: SessionCookie(),
			};
		}
	}

	try {
		switch (props.callType) {
		case 'get':
			return getRequest(props);
		case 'post':
			return postRequest(props);
		case 'put':
			return putRequest(props);
		case 'delete':
			return deleteRequest(props);
		default:
			return getRequest(props);
		}
	}
	catch (e) {
		console.log('error', e);
		// LogQuery({
		//   message: e.message,
		//   call: props.path,
		// })
	}
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LogQuery = (props) => {

	const data = {
		message: props.message,
		call: props.call,
		user: props.user,
	};

	const fetchData = () => {
		axios.post('/v1/Utility/LogSave', {
			responseType: 'application/json',
			withCredentials: true,
			data: data
		})
			.then(response => {
				console.log('LogQuery', response.data);
			})
			.catch(error => console.log('error', error));
	};

	fetchData();
};

const Errors = {
	'PathNotProvided': 'You need to provide a path to fetch a request.',
	'CallTypeNotProvided': 'You need to provide a valide call type.',
};

export { RunQuery };


export const ExternalRequest = async ({
	type,
	url,
	data,
	headers,
	log,
}) => {

	if (type === 'post') {
		const result = axios.post(url, data, {
			headers: headers,
		})
			.then(response => {
				if (log) {
					console.log(response.data);
				}

				return response.data;
			})
			.catch(err => {
				if (err.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(err.response.data);
					console.log(err.response.status);
					console.log(err.response.headers);
				} else if (err.request) {
					// The request was made but no response was received
					// `err.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(err.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', err.message);
				}
				console.log(err.config);
			});

		return await result;
	}
	else {
		const result = axios.post(url, data, {
			headers: headers,
		})
			.then(response => {
				if (log) {
					console.log(response.data);
				}

				return response.data;
			})
			.catch(err => {
				if (err.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(err.response.data);
					console.log(err.response.status);
					console.log(err.response.headers);
				} else if (err.request) {
					// The request was made but no response was received
					// `err.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(err.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', err.message);
				}
				console.log(err.config);
			});

		return await result;
	}
};
