import React, { useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Blockquote, BlockquoteOptions } from '@tiptap/extension-blockquote';
import { Bold, BoldOptions } from '@tiptap/extension-bold';
import { BulletList, BulletListOptions } from '@tiptap/extension-bullet-list';
import { Code, CodeOptions } from '@tiptap/extension-code';
import { CodeBlock, CodeBlockOptions } from '@tiptap/extension-code-block';
import { Document } from '@tiptap/extension-document';
import { Dropcursor, DropcursorOptions } from '@tiptap/extension-dropcursor';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import { HardBreak, HardBreakOptions } from '@tiptap/extension-hard-break';
import { Heading, HeadingOptions } from '@tiptap/extension-heading';
import { History, HistoryOptions } from '@tiptap/extension-history';
import { Italic, ItalicOptions } from '@tiptap/extension-italic';
import { ListItem, ListItemOptions } from '@tiptap/extension-list-item';
import { OrderedList, OrderedListOptions } from '@tiptap/extension-ordered-list';
import { Paragraph, ParagraphOptions } from '@tiptap/extension-paragraph';
import { Strike, StrikeOptions } from '@tiptap/extension-strike';
import { Text } from '@tiptap/extension-text';
/* eslint-enable @typescript-eslint/no-unused-vars */

import MenuBar from './component_parts/JiraTextEditorMenuBar';

/** Styles */
import 'remixicon/fonts/remixicon.css';
import './styles/_JiraTextEditor.css';
import './styles/JiraTextEditor.css';

const Tiptap = ({
    content,
    setDescription,
    editValue,
    setEditCallback,
    maxLines,
    clearContent,
    setClearContent,
}) => {

    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            Bold,
            Strike,
            Italic,
            Code,
            History,
            Heading.configure({
                levels: [1, 2, 3],
            }),
            CodeBlock,
            Blockquote,
            HardBreak,
            ListItem,
            BulletList,
            OrderedList,
        ],
        content: content,
        editable: editValue,
        injectCSS: false,
        onUpdate({ editor }) {
            setDescription(editor.getJSON());
        },
    });

    /**
     * Link immagini
     * src="https://badsoft.atlassian.net/secure/attachment/10011/10011_image-20221216-164652.png"
     */

    const handleEditable = () => {
        if (setEditCallback) {
            if (!editor.editable) {
                setEditCallback(true);
            }
            else {
                setEditCallback(false);
            }
        }
    };

    useEffect(() => {
        if (editor) {
            editor.setEditable(editValue);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [editValue]);

    useEffect(() => {
        if (clearContent && setClearContent) {
            editor.commands.clearContent(true);
            setClearContent(false);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [clearContent]);

    return (
        <div
            className={`mb-2 ${editValue ? 'border' : ''} ${maxLines && maxLines === 3 ? 'has-max-3-lines-as-default' : ''}`}
            onClick={handleEditable}
        >
            {editValue && <MenuBar editor={editor} />}
            <EditorContent editor={editor} />
        </div>
    );
};

export default Tiptap;

export const StaticComment = ({
    content,
}) => {

    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            Bold,
            Bold.configure({
                types: [
                    'strong',
                    'bold',
                ],
            }),
            Strike,
            Italic,
            Code,
            History,
            Heading.configure({
                levels: [1, 2, 3],
            }),
            CodeBlock,
            Blockquote,
            HardBreak,
            ListItem,
            BulletList,
            OrderedList,
        ],
        content: content,
        enabled: false,
        injectCSS: false,
    });

    return (
        <>
            {editor && <div dangerouslySetInnerHTML={{ __html: editor.getHTML() }} />}
        </>
    );
};