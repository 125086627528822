import { RunQuery } from '../utility/Fetch';
import { IAddCommentModel, ICreateTicketModel, IGetTicketsModel, IUpdateTickeModel } from './interfaces/IJiraModel';

export const GetTickets = async (data: IGetTicketsModel): Promise<JSON> => {

    const response = await RunQuery({
        path: 'v1/Jira/Tickets',
        callType: 'get',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetTickets > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const GetTicket = async (key: string): Promise<JSON> => {

    const data = {
        key: key,
    };

    const response = await RunQuery({
        path: 'v1/Jira/Ticket',
        callType: 'get',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetTicket > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const CreateTicket = async (data: ICreateTicketModel): Promise<JSON> => {

    // console.log('CreateTicket', data);

    const response = await RunQuery({
        path: 'v1/Jira/Ticket',
        callType: 'post',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > CreateTicket > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const UpdateTicket = async (data: IUpdateTickeModel): Promise<JSON> => {

    // console.log('CreateTicket', data);

    const response = await RunQuery({
        path: 'v1/Jira/Ticket',
        callType: 'put',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > UpdateTicket > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const AddComment = async (data: IAddCommentModel): Promise<JSON> => {

    // console.log('AddComment', data);

    const response = await RunQuery({
        path: 'v1/Jira/Comment',
        callType: 'post',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > AddComment > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};