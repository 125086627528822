import { trackPromise } from 'react-promise-tracker';
import { RunQuery } from '../utility/Fetch';
import { ISaveSettingModel, ISetting } from './interfaces/ISettingsMethods';
import { IGetAllFilter } from './interfaces/IShared';


export const GetSettings = async (data: IGetAllFilter): Promise<Array<ISetting>> => {

    const response = await trackPromise(RunQuery({
        path: 'v1/Configurations/Settings',
        callType: 'get',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetSettings > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        }));

    return await response;
};

export const GetSetting = async (id: string): Promise<ISetting> => {

    const response = await trackPromise(RunQuery({
        path: 'v1/Configurations/Setting',
        callType: 'get',
        data: {
            id: id,
        },
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetSetting > response:', response);

            if (response?.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        }));

    return await response;
};

export const SaveSetting = async (data: ISaveSettingModel): Promise<ISetting> => {

    const response = await trackPromise(RunQuery({
        path: 'v1/Configurations/Setting',
        callType: 'post',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > SaveSetting > response:', response);

            if (response?.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        }));

    return await response;
};

export const DeleteSetting = async (id: string): Promise<boolean> => {

    const response = await trackPromise(RunQuery({
        path: 'v1/Configurations/Setting',
        callType: 'delete',
        data: {
            id: id,
        },
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > DeleteSetting > response:', response);

            if (response?.Error.ErrorCode) {
                return false;
            }

            return true;
        }));

    return await response;
};
