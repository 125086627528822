import cryptoJs from 'crypto-js';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import { UserHasPermissions } from '../methods/UserMethods';
import { UserModel } from '../models/UserModel';
import { UserContextAction } from './enums/UserContextEnum';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY as string;

const encryptUser = (session: UserModel): string => cryptoJs.AES.encrypt(JSON.stringify(session), SECRET_KEY).toString();
const decryptUser = (session: string): UserModel => {
    const decrypted = cryptoJs.AES.decrypt(session, SECRET_KEY).toString(cryptoJs.enc.Utf8);
    return JSON.parse(decrypted.length > 0 ? decrypted : '{}') as UserModel;
};

type SetUser = { user: UserModel; }

type UserContextType = {
    user: UserModel | undefined;
    setUser: (params: SetUser) => void;
    destroyUser: () => void;
}

type Actions =
    | ({ type: UserContextAction.SetUser } & SetUser)
    | ({ type: UserContextAction.DestroyUser });

const UserContext = createContext<UserContextType>({
    user: undefined,
    setUser: () => null,
    destroyUser: () => null,
});

const userReducer = (state: UserModel | undefined, action: Actions): UserModel | undefined => {
    switch (action.type) {
        case UserContextAction.SetUser:
            return {
                ...state,
                ...action.user,
            };
        case UserContextAction.DestroyUser:
            return undefined;
        default:
            return state;
    }
};

export const UserProvider = ({
    children,
    storeKey = 'bs-user',
}: {
    children: ReactNode,
    storeKey?: string,
}) => {

    const localData = useRef(localStorage.getItem(storeKey));

    const [user, dispatch] = useReducer(userReducer, localData.current ? decryptUser(localData.current) : undefined);

    const setUser = useCallback<UserContextType['setUser']>(
        (params) => {
            dispatch({ type: UserContextAction.SetUser, ...params });
        },
        [dispatch]
    );

    const destroyUser = useCallback<UserContextType['destroyUser']>(
        () => dispatch({ type: UserContextAction.DestroyUser }),
        [dispatch]
    );

    useEffect(() => {
        if (user) {
            localStorage.setItem(storeKey, encryptUser(user));
        }
    }, [user, storeKey]);

    return (
        <UserContext.Provider value={{ user, setUser, destroyUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);

export const useIsBetaTester = (): boolean => {
    const { user } = useUser();
    return user?.type === 'BetaUser';
};

export const UserRolePermissions = () => {
    const { user } = useUser();
    return user?.role?.permissions;
};

export const UserId = () => {
    const user = decryptUser(localStorage.getItem('bs-user') ?? '') as unknown as UserModel;
    return user?.id;
};

export const UserEmail = () => {
    const { user } = useUser();
    return user?.email;
};

export const UserName = () => {
    const { user } = useUser();
    return user?.name;
};

export const JiraAccountId = () => {
    const { user } = useUser();
    return user?.jiraAccountId;
};

export const useHasPermissions = (value: string | Array<string>): boolean => UserHasPermissions(value);
export const useHasUserPermissions = (value: string | Array<string>, userIdToCompare: string): boolean => UserHasPermissions(value, userIdToCompare);
export const useHasSelfPermissions = (value: string | Array<string>): boolean => UserHasPermissions(value, undefined, true);