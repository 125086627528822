import React, { createContext, ReactNode, useCallback, useContext, useEffect, useReducer } from 'react';
import { CmsPage } from '../core/entities/CmsPage';
import { Carousel, CmsModule } from '../core/entities/Module';
import { CmsContextAction } from './enums/CmsContextEnum';
import { useHasPermissions, UserId } from './UserContext';

// Models
import { CarouselItemModel } from '../models/ModuleModel';
import { ModuleOptionsModel } from '../models/ModuleOptionsModel';

type SetCmsPage = { cmsPage: CmsPage; }
type UpdatePageOptions = {
    options: {
        title: string,
        description: string,
        language: number,
        active: boolean,
        footer: boolean,
    }
}
type UpdateModuleOptions = { moduleId: string, options: ModuleOptionsModel }
type UpdateModule = { moduleId: string, payload: CmsModule }
type UpdateUrl = { parentId: string | undefined, url: string }
type AddModule = { module: CmsModule; }
type RemoveModule = { id: string; }
type SortModules = { modules: Array<CmsModule>; }

type CmsContextType = {
    cmsPage: CmsPage,
    setCmsPage: (params: SetCmsPage) => void;
    updatePageOptions: (params: UpdatePageOptions) => void;
    updateModuleOptions: (params: UpdateModuleOptions) => void;
    updateModule: (params: UpdateModule) => void;
    updateUrl: (params: UpdateUrl) => void;
    addModule: (params: AddModule) => void;
    removeModule: (params: RemoveModule) => void;
    sortModules: (params: SortModules) => void;
};

type Actions =
    | ({ type: CmsContextAction.SetCmsPage } & SetCmsPage)
    | ({ type: CmsContextAction.UpdatePageOptions } & UpdatePageOptions)
    | ({ type: CmsContextAction.UpdateModuleOptions } & UpdateModuleOptions)
    | ({ type: CmsContextAction.UpdateModule } & UpdateModule)
    | ({ type: CmsContextAction.UpdateUrl } & UpdateUrl)
    | ({ type: CmsContextAction.AddModule } & AddModule)
    | ({ type: CmsContextAction.RemoveModule } & RemoveModule)
    | ({ type: CmsContextAction.SortModules } & SortModules);

const createCmsPage = () => {
    return {
        active: false,
        footer: true,
        language: 0,
        modules: [],
        type: 'cms',
        visibleInNavigation: true,
    };
};

const CmsContext = createContext<CmsContextType>({
    cmsPage: createCmsPage(),
    setCmsPage: () => null,
    updatePageOptions: () => null,
    updateModuleOptions: () => null,
    updateModule: () => null,
    updateUrl: () => null,
    addModule: () => null,
    removeModule: () => null,
    sortModules: () => null,
});

const cmsReducer = (state: CmsPage, action: Actions): CmsPage => {

    if (!state) {
        state = createCmsPage();
    }

    switch (action.type) {
        case CmsContextAction.SetCmsPage:
            state = action.cmsPage as CmsPage;
            break;
        case CmsContextAction.UpdatePageOptions:
            state = {
                ...state,
                ...action.options,
            };
            break;
        case CmsContextAction.UpdateModuleOptions:
            state.modules.map(module => {
                if (module.id === action.moduleId) {
                    module.moduleOptions = {
                        ...module.moduleOptions,
                        ...action.options,
                    };
                }
                return module;
            });
            break;
        case CmsContextAction.UpdateModule:
            state.modules = [
                ...state.modules.map(module => {
                    if (module.id === action.moduleId) {
                        module = {
                            ...module,
                            ...action.payload,
                        };
                    }
                    return module;
                })
            ];
            break;
        case CmsContextAction.UpdateUrl:
            state = {
                ...state,
                ...action,
            };
            break;
        case CmsContextAction.AddModule:
            state.modules.push({
                ...action.module,
                defaultVisible: true,
            });
            break;
        case CmsContextAction.RemoveModule:
            state.modules = state.modules
                .filter(_ => _.id !== action.id)
                .sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                .map((item: CmsModule, index: number) => {
                    item.sortOrder = index;
                    return item;
                });
            break;
        case CmsContextAction.SortModules:
            state.modules = action.modules;
            break;
        default:
            return state;
    }

    return { ...state };
};

export const CmsProvider = ({
    children,
    storeKey = 'bs-local-cms',
}: {
    children: ReactNode,
    storeKey?: string,
}) => {

    const [cmsPage, dispatch] = useReducer(
        cmsReducer,
        createCmsPage()
    );

    const setCmsPage = useCallback<CmsContextType['setCmsPage']>(
        (params) => {
            dispatch({ type: CmsContextAction.SetCmsPage, ...params });
        },
        [dispatch]
    );

    const updatePageOptions = useCallback<CmsContextType['updatePageOptions']>(
        (params) => {
            dispatch({ type: CmsContextAction.UpdatePageOptions, ...params });
        },
        [dispatch]
    );

    const updateModuleOptions = useCallback<CmsContextType['updateModuleOptions']>(
        (params) => {
            dispatch({ type: CmsContextAction.UpdateModuleOptions, ...params });
        },
        [dispatch]
    );

    const updateModule = useCallback<CmsContextType['updateModule']>(
        (params) => {
            dispatch({ type: CmsContextAction.UpdateModule, ...params });
        },
        [dispatch]
    );

    const updateUrl = useCallback<CmsContextType['updateUrl']>(
        (params) => {
            dispatch({ type: CmsContextAction.UpdateUrl, ...params });
        },
        [dispatch]
    );

    const addModule = useCallback<CmsContextType['addModule']>(
        (params) => {
            dispatch({ type: CmsContextAction.AddModule, ...params });
        },
        [dispatch]
    );

    const removeModule = useCallback<CmsContextType['removeModule']>(
        (params) => {
            dispatch({ type: CmsContextAction.RemoveModule, ...params });
        },
        [dispatch]
    );

    const sortModules = useCallback<CmsContextType['sortModules']>(
        (params) => {
            dispatch({ type: CmsContextAction.SortModules, ...params });
        },
        [dispatch]
    );

    // !? TODO remove the localStorage? for now is useful to have
    useEffect(() => {
        console.log('update local storage', storeKey, cmsPage);
        localStorage.setItem(storeKey, JSON.stringify(cmsPage));
    }, [cmsPage, storeKey]);

    return (
        <CmsContext.Provider
            value={{ cmsPage, setCmsPage, updatePageOptions, updateModuleOptions, updateModule, updateUrl, addModule, removeModule, sortModules }}
        >
            {children}
        </CmsContext.Provider>
    );
};

export const useCms = () => useContext(CmsContext);

export const useCmsPage = () => {

    const { cmsPage } = useCms();

    const parentId = cmsPage.parentId;

    const pageOptions = {
        title: cmsPage.title ?? '',
        description: cmsPage.description ?? '',
        language: cmsPage.language,
        active: cmsPage.active,
        footer: cmsPage.footer,
    };

    const modules = cmsPage.modules;
    const modulesCount = modules.length;

    return { parentId, pageOptions, modules, modulesCount };
};

export const useCmsPageUrl = () => {

    const { cmsPage } = useCms();

    const getPageUrl = (url: string | undefined) => {

        if (!url) {
            return null;
        }

        if (url.includes('/')) {
            const urlParts = url.split('/');
            return urlParts[urlParts.length - 1];
        }

        return url;
    };

    const getParentUrl = (url: string | undefined) => {

        if (!url) {
            return null;
        }

        /* eslint-disable-next-line no-useless-escape */
        const regex = /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)/;
        const result = regex.exec(url);

        if (result) {
            return result[0];
        }

        return null;
    };

    const createFullUrl = (parent: string | undefined, child: string) => {

        if (!parent) {
            if (child.startsWith('/')) {
                return child;
            }

            return `/${child}`;
        }

        let result = parent;

        if (!parent.endsWith('/')) {
            result += '/';
        }

        if (child.startsWith('/')) {
            result.slice(0, result.length - 1);
        }

        result += child;

        return result;
    };

    const url = cmsPage.url;

    return { url, getPageUrl, getParentUrl, createFullUrl };
};

export const useCmsPageModule = (id: string) => {

    const { cmsPage } = useCms();

    const module = cmsPage.modules?.find(_ => _.id === id);
    const moduleOptions = module?.moduleOptions;

    return { module, moduleOptions };
};

export const useCarouselItem = (moduleId: string, identifier: string): CarouselItemModel => {

    const { module } = useCmsPageModule(moduleId);

    if (!module) {
        return {};
    }

    const carouselItem = (module as Carousel).items.find(_ => _.identifier === identifier);

    if (!carouselItem) {
        return {};
    }

    return carouselItem;
};

export const IsCmsContentEnabled = (): boolean => {

    const { cmsPage } = useCms();
    const hasFullAccess = useHasPermissions('canEditAllCMSPages');
    const hasOwnAccess = useHasPermissions('canEditOwnCMSPages');

    if (hasFullAccess) {
        return true;
    }
    else if (hasOwnAccess && cmsPage.createdBy?.id === UserId()) {
        return true;
    }
    else {
       return false;
    }
};