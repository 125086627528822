/**
 * The actions enum
 */
export enum SessionActions {
    /**
     * Set the session
     */
    SetSession = 'SET_SESSION',

    /**
     * Destroy the session
     */
    DestroySession = 'DESTROY_SESSION',
}