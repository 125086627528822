/* eslint-disable @typescript-eslint/no-explicit-any */

import { UserId, UserRolePermissions, useUser } from '../contexts/UserContext';
import { IUser } from '../entities/Admin';
import { GetUser } from '../methods/AdminMethods';
import { UserModel } from '../models/UserModel';

/* eslint-disable-next-line @typescript-eslint/no-var-requires */
const locationPermissions = require('../data/location_permissions.json');

/**
 * Check if the logged user has the permissions
 * @param required The permission that need to be checked
 * @param allPermissions If true all permissions must satisfacting the required ones
 * @returns True if has permission otherwise false
 */
export const UnusedhasPermissions = (required: string[], allPermissions = false): boolean => {

    const permissions = getLocationPermissions();

    if (allPermissions) {
        return permissions.every((permission: any) => required?.includes(permission));
    }

    return permissions.some((permission: any) => required?.includes(permission));
};

/**
 * Get the location permission
 * @returns The permission for the current location
 */
export const getLocationPermissions = (): Array<string> => {

    const location = window.location.pathname;

    const permissions = locationPermissions
        .find(((permission: any) => permission.location?.startsWith ? location.startsWith(permission.location) : permission.location === location));

    return permissions?.permissions || permissions?.users;
};


/**
 * Check if the current user has the given permission(s)
 * @param {string | Array<string>} value Could be a string or an array of strings
 * @returns true if has permission, otherwise false
 */
export const useUserHasPermission = (value: string | Array<string>, userIdToCompare: string | undefined, isUserPermission = false) => {

    const { user } = useUser();
    const permissions = UserRolePermissions();
    const userId: string = UserId() as string;
    const isBetaTester: boolean = useIsBetaTester();

    if (isUserPermission) {
        return user?.permissions?.find((_: { identifier: string; }) => value.includes(_.identifier));
    }

    /** This is e.g. for the keychain */
    if (value?.includes(userId)) {
        return true;
    }

    if (isBetaTester) {
        return true;
    }

    if (!permissions) {
        return null;
    }

    let hasPermissions = undefined;

    if (Array.isArray(value)) {
        hasPermissions = permissions.find(_ => value.includes(_.identifier));
    }
    else {
        hasPermissions = permissions.some(_ => _.identifier === value);
    }

    if (!hasPermissions) {
        return false;
    }

    if (userIdToCompare) {
        return userId === userIdToCompare;
    }

    return true;
};

/**
 * Check if the logged user is a beta tester
 * @returns `true` if is a beta tester otherwise `false`
 */
export const useIsBetaTester = () => {

    const { user } = useUser();

    if (!user) {
        return false;
    }

    return user.type === 'BetaUser';
};

/**
 * Get the user id
 * @returns The id of logged user
 */
export const useUserId = () => {

    const { user } = useUser();

    if (!user) {
        return null;
    }

    return user.id;
};

/**
 * Get the user Jira account id
 * @returns The Jira account id of logged user
 */
export const useUserJiraAccountId = () => {

    const { user } = useUser();

    if (!user) {
        return null;
    }

    return user.jiraAccountId;
};

/**
 * Get the displayed name for a given user
 * @param {IUser} user The user identifier
 * @returns A `string` with displayed name
 */
export const getDisplayedName = (user: IUser | UserModel) => {

    if (!user) {
        return null;
    }

    let displayedName = user.email;

    if (user?.name || user?.lastname) {
        displayedName = user.name ? `${user.name} ` : '';
        displayedName += user.lastname ? ` ${user.lastname}` : '';
    }
    else if (user?.username) {
        displayedName = user.username;
    }

    return displayedName?.trim() ?? 'Username';
};

/**
 * Check if a given user is part of BadSoft teams
 * @param {string} userId The user identifier, if null will taken the logged one
 * @returns `true` if is a member of BadSoft teams, otherwise `false`
 */
export const useBadSoftUser = (userId: string) => {

    const { user } = useUser();

    if (userId) {
        GetUser(userId)
            .then(response => {
                return response?.badSoftUser;
            });
    }
    else if (user) {
        return user.badSoftUser;
    }
    else {
        return false;
    }
};