import { ReactNode } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { IsValidSession } from '../contexts/SessionContext';

const PrivateRoute = ({
    children,
}: {
    children: ReactNode,
}) => {

    const location: Location = useLocation();
    const redirect = () => `?redirectTo=${location.pathname}${location.search}`;

    if (!IsValidSession()) {
        window.location.href = `/login${redirect()}`;
    }

    return children;
};

export default PrivateRoute;