/* eslint-disable @typescript-eslint/no-explicit-any */

import { trackPromise } from 'react-promise-tracker';
import { UserId } from '../contexts/UserContext';
import {
    IActivateUserDataModel, IChangePasswordDataModel, IPermission,
    IPermissionsFilter,
    IRole, IRolePermissions, IRolesFilter, IUser,
    IUsersFilter
} from '../entities/Admin';
import { ErrorResponse, IDefaultResponse } from '../entities/Shared';
import { RunQuery } from '../utility/Fetch';

/**
 * Login to the FronEnd
 * @param {The email} email
 * @param {The password} password
 */
export const PostLogin = async (email: string, password: string): Promise<any> => {

    //#region Check parameters
    if (!email) {
        return {
            ErrorCode: 1,
            ErrorMessage: `Devi inserire ${_getErrorMessage('email')}`,
        };
    }

    if (!password) {
        return {
            ErrorCode: 1,
            ErrorMessage: `Devi inserire ${_getErrorMessage('password')}`,
        };
    }
    //#endregion

    const data = {
        email: email,
        password: password,
        source: process.env.REACT_APP_CLIENT_UUID,
    };

    const response = await RunQuery({
        path: 'v1/Admin/Login',
        callType: 'post',
        data: data,
        withCredentials: true,
        withoutCookie: true,
    })
        .then(response => {
            // console.log('RunQuery > Login > response:', response);

            /** The password need to be changed */
            if (response.Error.ErrorCode === 19) {
                return response;
            }

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

/**
 * Logout the current logged user
 */
export const Logout = async (): Promise<void> => {

    return;
};

const _getErrorMessage = (type: string): string => {

    switch (type) {
        case 'email':
            return 'un indirizzo email';
        case 'password':
            return 'una password';
        default:
            return 'i dati';
    }
};

export const GetUsers = async (data: IUsersFilter): Promise<Array<IUser>> => {

    const _data = {
        withActions: true,
        ...data
    };

    const response = await RunQuery({
        path: 'v1/Admin/Users',
        callType: 'get',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetUsers > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const DeleteUserById = async (id: string): Promise<boolean> => {

    const data = {
        id: id,
    };

    const response = await RunQuery({
        path: 'v1/Admin/User',
        callType: 'delete',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            console.log('RunQuery > DeleteUser > response:', response);

            if (response.Error.ErrorCode) {
                return false;
            }

            return true;
        });

    return await response;
};

export const GetUser = async (id: string): Promise<IUser> => {

    const _data = {
        id: id,
    };

    const response = await RunQuery({
        path: 'v1/Admin/User',
        callType: 'get',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetUser > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const SaveUser = async (data: IUser): Promise<IUser> => {

    const _data = {
        ...data
    };

    const response = await trackPromise(RunQuery({
        path: 'v1/Admin/User',
        callType: 'post',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            console.log('RunQuery > SaveUser > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        }));

    return await response;
};

export const GetPermissions = async (data: IPermissionsFilter): Promise<Array<IPermission>> => {

    const _data = {
        withActions: true,
        ...data
    };

    const response = await RunQuery({
        path: 'v1/Admin/Permissions',
        callType: 'get',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetPermissions > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const DeletePermissionById = async (id: string): Promise<boolean> => {

    const data = {
        id: id,
    };

    const response = await RunQuery({
        path: 'v1/Admin/Permission',
        callType: 'delete',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            console.log('RunQuery > DeletePermission > response:', response);

            if (response.Error.ErrorCode) {
                return false;
            }

            return true;
        });

    return await response;
};

export const GetPermission = async (id: string): Promise<IPermission> => {

    const _data = {
        id: id,
    };

    const response = await RunQuery({
        path: 'v1/Admin/Permission',
        callType: 'get',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetPermission > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const SavePermission = async (data: IPermission): Promise<IPermission> => {

    const _data = {
        ...data
    };

    const response = await RunQuery({
        path: 'v1/Admin/Permission',
        callType: 'post',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            console.log('RunQuery > SavePermission > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const GetRoles = async (data: IRolesFilter): Promise<Array<IRole>> => {

    const _data = {
        withActions: true,
        ...data,
    };

    const response = await RunQuery({
        path: 'v1/Admin/Roles',
        callType: 'get',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetRoles > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const DeleteRoleById = async (id: string): Promise<boolean> => {

    const data = {
        id: id,
    };

    const response = await RunQuery({
        path: 'v1/Admin/Role',
        callType: 'delete',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            console.log('RunQuery > DeleteRole > response:', response);

            if (response.Error.ErrorCode) {
                return false;
            }

            return true;
        });

    return await response;
};

export const GetRole = async (id: string): Promise<IRole> => {

    const _data = {
        id: id,
    };

    const response = await RunQuery({
        path: 'v1/Admin/Role',
        callType: 'get',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetRole > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const SaveRole = async (data: IRole): Promise<IRole> => {

    const _data = {
        ...data
    };

    const response = await RunQuery({
        path: 'v1/Admin/Role',
        callType: 'post',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            console.log('RunQuery > SaveRole > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const UpdateRolePermissions = async (data: IRolePermissions): Promise<IRole> => {

    const _data = {
        ...data
    };

    const response = await RunQuery({
        path: 'v1/Admin/UpdateRolePermissions',
        callType: 'post',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            console.log('RunQuery > UpdateRolePermissions > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const GetUserByEmailAndToken = async (email: string, token: string): Promise<IUser> => {

    const _data = {
        email: email,
        token: token,
        isPasswordChange: token === 'activated' ? true : false,
    };

    const response = await RunQuery({
        path: 'v1/Admin/ActivateUser',
        callType: 'get',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetUserByEmailAndToken > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const ActivateUser = async (data: IActivateUserDataModel): Promise<IDefaultResponse | ErrorResponse> => {

    data.sesssionCookie = 'FAKE_COOKIE';

    const response = await RunQuery({
        path: 'v1/Admin/ActivateUser',
        callType: 'post',
        data: data,
        withCredentials: true,
        withoutCookie: true,
    })
        .then(response => {
            // console.log('RunQuery > ActivateUser > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const ForgotPassword = async (email: string): Promise<IDefaultResponse | ErrorResponse> => {

    const data = {
        email: email,
    };

    const response = await RunQuery({
        path: 'v1/Admin/PasswordForgot',
        callType: 'post',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > PasswordForgot > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const GetForgotUserByEmailAndToken = async (email: string, token: string): Promise<IUser> => {

    const _data = {
        email: email,
        token: token,
    };

    const response = await RunQuery({
        path: 'v1/Admin/PasswordForgot',
        callType: 'get',
        data: _data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > GetForgotUserByEmailAndToken > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};

export const SendBetaUserInvitation = async (email: string): Promise<boolean | ErrorResponse> => {

    const response = await RunQuery({
        path: 'v1/Admin/BetaUserInvitation',
        callType: 'post',
        data: {
            id: UserId(),
            email: email,
        },
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > SendBetaUserInvitation > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return true;
        });

    return await response;
};

export const ChangePassword = async (data: IChangePasswordDataModel): Promise<IDefaultResponse | ErrorResponse> => {

    const response = await RunQuery({
        path: 'v1/Admin/ChangePassword',
        callType: 'post',
        data: data,
        withCredentials: true,
    })
        .then(response => {
            // console.log('RunQuery > ChangePassword > response:', response);

            if (response.Error.ErrorCode) {
                return response.Error;
            }

            return response.Result;
        });

    return await response;
};
