import React, { lazy, Suspense } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

/** Styles */
import './styles/CustomApp.css';

/** Hooks */
import { useBadSoftUser } from './utility/UserUtility';

/** Components */
import PrivateRoute from './components/PrivateRoute2';
import SettingsLayout from './views/Settings/_Layout';
import Layout from './views/_Layout';

/** Contexts */
import { CmsProvider } from './contexts/CmsContext';

/** Views */
const CmsEditor = lazy(() => import('./views/CmsEditor2'));
const CMSPageDelete = lazy(() => import('./views/CMSPageDelete'));
const CMSSitemap = lazy(() => import('./views/CMSSitemap'));
const HomePage = lazy(() => import('./views/HomePage'));
const Login = lazy(() => import('./views/Login2'));
const MediaManagement = lazy(() => import('./views/MediaManagement'));

/** Settings Views */
const ActivationIndex = lazy(() => import('./views/Activation/Index'));
const BadSoftDashboard = lazy(() => import('./views/BadSoft/Index'));
const BetaIndex = lazy(() => import('./views/BetaTesing/Index'));
const DeleteBetaUser = lazy(() => import('./views/BetaTesing/Tester/Delete'));
const EditBetaUser = lazy(() => import('./views/BetaTesing/Tester/Edit'));
const BetaUserIndex = lazy(() => import('./views/BetaTesing/Tester/Index'));
const MyInvitation = lazy(() => import('./views/BetaTesing/Tester/MyInvitation'));
const TicketClosed = lazy(() => import('./views/BetaTesing/Ticket/Closed'));
const TicketInProgress = lazy(() => import('./views/BetaTesing/Ticket/InProgess'));
const MyTickets = lazy(() => import('./views/BetaTesing/Ticket/MyTickets'));
const TicketOnTest = lazy(() => import('./views/BetaTesing/Ticket/OnTest'));
const TicketOpen = lazy(() => import('./views/BetaTesing/Ticket/Open'));
const TicketOverview = lazy(() => import('./views/BetaTesing/Ticket/Overview'));
const TicketDetail = lazy(() => import('./views/BetaTesing/Ticket/Ticket'));
const BetaTestingLayout = lazy(() => import('./views/BetaTesing/_Layout'));
const ForgotIndex = lazy(() => import('./views/Forgot/Index'));
const PasswordChange = lazy(() => import('./views/PasswordChange'));
const Index = lazy(() => import('./views/Settings/Index'));
const DeleteKeychainEntry = lazy(() => import('./views/Settings/Keychain/Delete'));
const EditKeychainEntry = lazy(() => import('./views/Settings/Keychain/Edit'));
const KeychainIndex = lazy(() => import('./views/Settings/Keychain/Index'));
const DeletePermission = lazy(() => import('./views/Settings/UserRoles/Permission/Delete'));
const EditPermission = lazy(() => import('./views/Settings/UserRoles/Permission/Edit'));
const PermissionIndex = lazy(() => import('./views/Settings/UserRoles/Permission/Index'));
const DeleteRole = lazy(() => import('./views/Settings/UserRoles/Role/Delete'));
const EditRole = lazy(() => import('./views/Settings/UserRoles/Role/Edit'));
const RoleIndex = lazy(() => import('./views/Settings/UserRoles/Role/Index'));
const DeleteUser = lazy(() => import('./views/Settings/UserRoles/User/Delete'));
const EditUser = lazy(() => import('./views/Settings/UserRoles/User/Edit'));
const UserIndex = lazy(() => import('./views/Settings/UserRoles/User/Index'));
const EditTimeReport = lazy(() => import('./views/TimeReport/Edit'));
const TimeReport = lazy(() => import('./views/TimeReport/Index'));
const ModuleOptionsIndex = lazy(() => import('./views/Settings/CMS/ModuleOptions/Index'));
const VerticalMarginEdit = lazy(() => import('./views/Settings/CMS/ModuleOptions/VerticalMargin/Edit'));
const DeleteVerticalMargin = lazy(() => import('./views/Settings/CMS/ModuleOptions/VerticalMargin/Delete'));
const BackgroundColorEdit = lazy(() => import('./views/Settings/CMS/ModuleOptions/BackgroundColor/Edit'));
const DeleteBackgroundColor = lazy(() => import('./views/Settings/CMS/ModuleOptions/BackgroundColor/Delete'));

const Fallback = () => {

	return (
		<Container fluid className='h-100'>
			<Row className='h-100'>
				<Col className='text-center align-self-center'>
					<Spinner
						as='span'
						animation='grow'
						size='lg'
						role='status'
						aria-hidden='true'
					/>
				</Col>
			</Row>
		</Container>
	);
};

function App() {

	const isBadSoftUser = useBadSoftUser();

	return (
		<div className='App'>
			<BrowserRouter>
				<Suspense fallback={<Fallback />}>
					<Routes>
						<Route path='/login' element={<Login />} />
						<Route path='/activate/:token/:email' element={<ActivationIndex />} />
						<Route path='/forgot/:token/:email' element={<ForgotIndex />} />
						<Route path='/passwordchange/:token/:id/:displayedname' element={<PasswordChange />} />
						<Route path='/' element={<PrivateRoute><Layout><HomePage /></Layout></PrivateRoute>} />
						<Route path='home' element={<PrivateRoute><Layout><HomePage /></Layout></PrivateRoute>} />
						<Route path='/cms'>
							<Route index element={<PrivateRoute><CmsProvider><Layout><CMSSitemap /></Layout></CmsProvider></PrivateRoute>} />
							<Route path='editor/:id' element={<PrivateRoute><CmsProvider><Layout><CmsEditor /></Layout></CmsProvider></PrivateRoute>} />
							<Route path='delete/:id' element={<PrivateRoute><CmsProvider><Layout><CMSPageDelete /></Layout></CmsProvider></PrivateRoute>} />
							<Route path='media-management' element={<PrivateRoute><CmsProvider><Layout><MediaManagement /></Layout></CmsProvider></PrivateRoute>} />
						</Route>
						<Route path='/settings'>
							<Route index element={<PrivateRoute><SettingsLayout><Index /></SettingsLayout></PrivateRoute>} />
							<Route path='users'>
								<Route index element={<PrivateRoute><SettingsLayout><UserIndex /></SettingsLayout></PrivateRoute>} />
								<Route path='edit'>
									<Route index element={<PrivateRoute><SettingsLayout><EditUser /></SettingsLayout></PrivateRoute>} />
									<Route path=':id' element={<PrivateRoute><SettingsLayout><EditUser /></SettingsLayout></PrivateRoute>} />
								</Route>
								<Route path='delete/:id' element={<PrivateRoute><SettingsLayout><DeleteUser /></SettingsLayout></PrivateRoute>} />
								<Route path='permissions'>
									<Route index element={<PrivateRoute><SettingsLayout><PermissionIndex /></SettingsLayout></PrivateRoute>} />
									<Route path='edit'>
										<Route index element={<PrivateRoute><SettingsLayout><EditPermission /></SettingsLayout></PrivateRoute>} />
										<Route path=':id' element={<PrivateRoute><SettingsLayout><EditPermission /></SettingsLayout></PrivateRoute>} />
									</Route>
									<Route path='delete/:id' element={<PrivateRoute><SettingsLayout><DeletePermission /></SettingsLayout></PrivateRoute>} />
								</Route>
								<Route path='roles'>
									<Route index element={<PrivateRoute><SettingsLayout><RoleIndex /></SettingsLayout></PrivateRoute>} />
									<Route path='edit'>
										<Route index element={<PrivateRoute><SettingsLayout><EditRole /></SettingsLayout></PrivateRoute>} />
										<Route path=':id' element={<PrivateRoute><SettingsLayout><EditRole /></SettingsLayout></PrivateRoute>} />
									</Route>
									<Route path='delete/:id' element={<PrivateRoute><SettingsLayout><DeleteRole /></SettingsLayout></PrivateRoute>} />
								</Route>
							</Route>
							<Route path='keychain'>
								<Route index element={<PrivateRoute><SettingsLayout><KeychainIndex /></SettingsLayout></PrivateRoute>} />
								<Route path='edit'>
									<Route index element={<PrivateRoute><SettingsLayout><EditKeychainEntry /></SettingsLayout></PrivateRoute>} />
									<Route path=':id' element={<PrivateRoute><SettingsLayout><EditKeychainEntry /></SettingsLayout></PrivateRoute>} />
								</Route>
								<Route path='delete/:id' element={<PrivateRoute><SettingsLayout><DeleteKeychainEntry /></SettingsLayout></PrivateRoute>} />
							</Route>
							<Route path='timereport'>
								<Route index element={<PrivateRoute><SettingsLayout><TimeReport /></SettingsLayout></PrivateRoute>} />
								<Route path='edit/:id' element={<PrivateRoute><SettingsLayout><EditTimeReport /></SettingsLayout></PrivateRoute>} />
							</Route>
							<Route path='cms'>
								<Route index element={<PrivateRoute><SettingsLayout><ModuleOptionsIndex /></SettingsLayout></PrivateRoute>} />
								<Route path='module-options'>
									<Route index element={<PrivateRoute><SettingsLayout><ModuleOptionsIndex /></SettingsLayout></PrivateRoute>} />
									<Route path='vertical-margins'>
										<Route index element={<PrivateRoute><SettingsLayout><VerticalMarginEdit /></SettingsLayout></PrivateRoute>} />
										<Route path=':id' element={<PrivateRoute><SettingsLayout><VerticalMarginEdit /></SettingsLayout></PrivateRoute>} />
										<Route path='delete/:id' element={<PrivateRoute><SettingsLayout><DeleteVerticalMargin /></SettingsLayout></PrivateRoute>} />
									</Route>
									<Route path='background-color'>
										<Route index element={<PrivateRoute><SettingsLayout><BackgroundColorEdit /></SettingsLayout></PrivateRoute>} />
										<Route path=':id' element={<PrivateRoute><SettingsLayout><BackgroundColorEdit /></SettingsLayout></PrivateRoute>} />
										<Route path='delete/:id' element={<PrivateRoute><SettingsLayout><DeleteBackgroundColor /></SettingsLayout></PrivateRoute>} />
									</Route>
								</Route>
							</Route>
							{isBadSoftUser &&
								<Route path='badsoft'>
									<Route index element={<PrivateRoute><SettingsLayout><BadSoftDashboard /></SettingsLayout></PrivateRoute>} />
								</Route>}
						</Route>
						<Route path='/betatest'>
							<Route index element={<PrivateRoute><BetaTestingLayout><BetaIndex /></BetaTestingLayout></PrivateRoute>} />
							<Route path='betatester'>
								<Route index element={<PrivateRoute><BetaTestingLayout><BetaUserIndex /></BetaTestingLayout></PrivateRoute>} />
								<Route path='edit/:id' element={<PrivateRoute><BetaTestingLayout><EditBetaUser /></BetaTestingLayout></PrivateRoute>} />
								<Route path='delete/:id' element={<PrivateRoute><BetaTestingLayout><DeleteBetaUser /></BetaTestingLayout></PrivateRoute>} />
							</Route>
							<Route path='my-invitations'>
								<Route index element={<PrivateRoute><BetaTestingLayout><MyInvitation /></BetaTestingLayout></PrivateRoute>} />
							</Route>
							<Route path='tickets'>
								<Route index element={<PrivateRoute><BetaTestingLayout><MyTickets /></BetaTestingLayout></PrivateRoute>} />
								<Route path='open' element={<PrivateRoute><BetaTestingLayout><TicketOpen /></BetaTestingLayout></PrivateRoute>} />
								<Route path='in-progress' element={<PrivateRoute><BetaTestingLayout><TicketInProgress /></BetaTestingLayout></PrivateRoute>} />
								<Route path='on-test' element={<PrivateRoute><BetaTestingLayout><TicketOnTest /></BetaTestingLayout></PrivateRoute>} />
								<Route path='closed' element={<PrivateRoute><BetaTestingLayout><TicketClosed /></BetaTestingLayout></PrivateRoute>} />
								<Route path='all' element={<PrivateRoute><BetaTestingLayout><TicketOverview /></BetaTestingLayout></PrivateRoute>} />
								<Route path='ticket'>
									<Route index element={<PrivateRoute><BetaTestingLayout><TicketDetail /></BetaTestingLayout></PrivateRoute>} />
									<Route path=':key' element={<PrivateRoute><BetaTestingLayout><TicketDetail /></BetaTestingLayout></PrivateRoute>} />
								</Route>
							</Route>
						</Route>
					</Routes>
				</Suspense>
			</BrowserRouter>
		</div >
	);
}

export default App;
