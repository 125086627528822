import React, { createContext, useContext, useEffect, useState } from 'react';
import { GetSettings } from '../methods/SettingsMethods';


export const SettingsContext = createContext();

export const SettingsContextProvider = ({
    children
}) => {

    const [settings, setSettings] = useState([]);

    useEffect(() => {
        GetSettings()
            .then(response => {
                setSettings(response);
            });
    }, []);

    return (
        <SettingsContext.Provider
            value={{
                settings
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => {

    const value = useContext(SettingsContext);

    if (value === undefined) {
        throw new Error('Context must be used within a Provider');
    }

    return value;
};

/**
 * Get a settings group
 * @param {string} group The setting's group name, if omitted the `default`will be taken
 * @returns The settings group `object` if found, otherwise `null`
 */
export const useSettingsGroup = (group) => {

    const { settings } = useSettings();

    if (!settings || settings.length === 0) {
        return null;
    }

    const settingsGroup = settings.find(_ => _.group === (group ?? 'default'));

    if (!settingsGroup) {
        return null;
    }

    return settingsGroup;
};

/**
 * Get a setting object
 * @param {string} key The key of the setting
 * @param {string} group The setting's group name, if omitted the `default`will be taken
 * @returns The setting `object` if found, otherwise `null`
 */
export const useSetting = (key, group) => {

    const settingsGroup = useSettingsGroup(group);

    if (!settingsGroup) {
        return null;
    }

    const setting = settingsGroup.settings.find(_ => _.key === key);

    if (!setting) {
        return null;
    }

    return setting;
};

/**
 * Get a boolean settings value
 * @param {string} key The key of the setting
 * @param {string} group The setting's group name, if omitted the `default`will be taken
 * @returns The setting value (`true` or `false`) if found, otherwise `false`
 */
export const useBoolSetting = (key, group) => {

    const setting = useSetting(key, group);

    if (!setting) {
        return false;
    }

    return setting.value;
};