/**
 * The actions enum
 */
export enum CmsContextAction {
    /**
     * Set the CMS page
     */
    SetCmsPage = 'SET_CMS_PAGE',

    /**
     * Update page options
     */
    UpdatePageOptions = 'UPDATE_PAGE_OPTIONS',

    /**
     * Update module options
     */
    UpdateModuleOptions = 'UPDATE_MODULE_OPTIONS',

    /**
     * Update module content
     */
    UpdateModule = 'UPDATE_MODULE',

    /**
     * Update page and parent URL
     */
    UpdateUrl = 'UPDATE_URL',

    /**
     * The add module action
     */
    AddModule = 'ADD_MODULE',

    /**
     * The remove module action
     */
    RemoveModule = 'REMOVE_MODULE',

    /**
     * The sort modules action
     */
    SortModules = 'SORT_MODULES',
}