import axios from 'axios';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThreeDots } from 'react-loader-spinner';
import { usePromiseTracker } from 'react-promise-tracker';
import App from './App';
import reportWebVitals from './reportWebVitals';

/** Importing extensions */
import './extensions/NumberExtensions';
// import './extensions/ObjectExtensions';
import './extensions/GeneralExtensions';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Importing the Open Iconic CSS
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import 'open-iconic/font/css/open-iconic.css';

// Importing the custom CSS
import './index.css';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

/** Importing locales */
import 'moment/locale/it';

/** Context providers */
import { ProSidebarProvider } from 'react-pro-sidebar';
import { SessionProvider } from './contexts/SessionContext';
import { UserProvider } from './contexts/UserContext';

moment.locale('it');

export const LoadingIndicator = () => {
	const { promiseInProgress } = usePromiseTracker();
	return (
		promiseInProgress &&
		<div
			style={{
				width: '100%',
				height: '100',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				zIndex: '9999999',
			}}
		>
			<ThreeDots type='ThreeDots' color='#2BAD60' height='100' width='100' />
		</div>
	);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	<SessionProvider>
		<UserProvider>
			<ProSidebarProvider>
				<App />
				<LoadingIndicator />
			</ProSidebarProvider>
		</UserProvider>
	</SessionProvider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
