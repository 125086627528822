Number.prototype.toSeconds = function () {
    return this / 1000;
};

Number.prototype.toMilliseconds = function () {
    return this * 1000;
};

Number.prototype.toCurrency = function (currency) {

    if (currency === undefined) {
        currency = 'CHF';
    }

    const numberFormatter = Intl.NumberFormat('de-CH');
    const formattedNumber = numberFormatter.format(this);

    return `${formattedNumber} ${currency}`;
};

Number.prototype.toPercent = function () {
    return this * 100;
};