import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface IToggleToastNotification {
    title: string,
    icon?: SweetAlertIcon,
    timer?: number,
    position?: SweetAlertPosition,
    showConfirmButton?: boolean,
    timerProgressBar?: boolean,
}

const ToastSwal = withReactContent(Swal);

export const ToggleToastNotification = async (data: IToggleToastNotification): Promise<void> => {

    const Toast = ToastSwal.mixin({
        toast: true,
        position: data?.position ?? 'top-end',
        showConfirmButton: data?.showConfirmButton || false,
        timer: data?.timer || 3000,
        timerProgressBar: data?.timerProgressBar || false,
        // didOpen: (toast) => {
        //     // toast.addEventListener('mouseenter', Swal.stopTimer);
        //     // toast.addEventListener('mouseleave', Swal.resumeTimer);
        // }
    });

    Toast.fire({
        icon: data?.icon ?? 'success',
        title: data.title
    });
};