import cryptoJs from 'crypto-js';
import moment from 'moment';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import { SessionActions } from './enums/SessionEnum';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY as string;

const encryptSession = (session: Session): string => cryptoJs.AES.encrypt(JSON.stringify(session), SECRET_KEY).toString();
const decryptSession = (session: string): Session => {
    const decrypted = cryptoJs.AES.decrypt(session, SECRET_KEY).toString(cryptoJs.enc.Utf8);
    return JSON.parse(decrypted.length > 0 ? decrypted : '{}') as Session;
};

type Session = {
    sessionCookie: string,
    expirationDate: string,
}

type SetSession = { cookie: string, date: string }

type SessionContextType = {
    session: Session | undefined,
    setSession: (params: SetSession) => void,
    destroySession: () => void,
}

type Actions =
    | ({ type: SessionActions.SetSession } & SetSession)
    | ({ type: SessionActions.DestroySession });

const SessionContext = createContext<SessionContextType>({
    session: undefined,
    setSession: () => null,
    destroySession: () => null,
});

const sessionReducer = (state: Session | undefined, action: Actions): Session | undefined => {
    switch (action.type) {
        case SessionActions.SetSession:
            state = {
                sessionCookie: action.cookie,
                expirationDate: action.date,
            };
            return { ...state };
        case SessionActions.DestroySession:
            state = undefined;
            localStorage.removeItem('bs-session');
            return;
    }
};

export const SessionProvider = ({
    children,
}: {
    children: ReactNode,
}) => {

    const localData = useRef(localStorage.getItem('bs-session'));

    const [session, dispatch] = useReducer(sessionReducer, localData.current ? decryptSession(localData.current) : undefined);

    const setSession = useCallback<SessionContextType['setSession']>(
        (params) => dispatch({ type: SessionActions.SetSession, ...params }),
        [dispatch]
    );

    const destroySession = useCallback<SessionContextType['destroySession']>(
        () => dispatch({ type: SessionActions.DestroySession }),
        [dispatch]
    );

    useEffect(() => {
        if (session) {
            const e = encryptSession(session);
            localStorage.setItem('bs-session', e);
        }
    }, [session]);

    return (
        <SessionContext.Provider value={{ session, setSession, destroySession }}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSession = () => useContext(SessionContext);

export const IsValidSession = (): boolean => {

    const { session } = useSession();

    const now = moment.parseZone();

    if (!session || now.isAfter(session.expirationDate)) {
        return false;
    }

    return true;
};

export const SessionCookie = () => {
    const session = decryptSession(localStorage.getItem('bs-session') ?? '');
    return session?.sessionCookie;
};