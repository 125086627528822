import { useIsBetaTester, useUser } from '../contexts/UserContext';
import permissionsData from '../data/location_permissions.json';
import { LocationPermissionsModel } from '../models/LocationPermissionsModel';

const LocationPermissions: Array<LocationPermissionsModel> = permissionsData;
// const Permission = (location: string): LocationPermissionsModel | undefined => Permissions.find(_ => _.location === location);

export const UserHasPermissions = (value: string | Array<string>, userIdToCompare: string | undefined = undefined, isUserPermission = false, isLocation = false): boolean => {

    const { user } = useUser();
    const isBetaTester = useIsBetaTester();

    if (user?.badSoftUser) {
        return true;
    }

    if (isUserPermission) {
        return user?.permissions?.some(_ => value.includes(_.identifier)) ?? false;
    }

    // Special case e.g. for keychain
    if (value.includes(user?.id as string)) {
        return true;
    }

    if (isBetaTester) {
        return true;
    }

    if (!Permissions) {
        return true;
    }

    if (userIdToCompare) {
        return user?.id === userIdToCompare;
    }

    if (isLocation) {
        return LocationPermissions.some(_ => _.permissions?.some(x => value.includes(x)));
    }

    const permissions = user?.role?.permissions;

    if (Array.isArray(value)) {
        return permissions?.some(_ => value.includes(_.identifier)) ?? false;
    }
    else {
        return permissions?.some(_ => _.identifier === value) ?? false;
    }
};