import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Menu, MenuItem, Sidebar, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SettingsContextProvider, useBoolSetting } from '../../contexts/SettingsContext';

import { getLocationPermissions, useBadSoftUser, useUserHasPermission } from '../../utility/UserUtility';

import Navbar from '../../components/Navbar';

/** Styles */
import '../../styles/Settings/Layout.css';

/** Icons */
import { GiBleedingEye } from 'react-icons/gi';
import { IoKey, IoKeyOutline, IoPeople, IoPeopleOutline, IoServer, IoServerOutline, IoSettings, IoSettingsOutline, IoTime, IoTimeOutline } from 'react-icons/io5';

/**
 *
 * @param {*} param0
 * @returns
 */
export const SettingsContainer = ({
    cols,
    children,
    // breadcrumb,
}) => {

    return (
        <Container fluid className='h-100 py-2'>
            {cols && cols > 1 ?
                <Row>
                    {children.length > 1 ? children.map(child => {

                        return (
                            <Col
                                key={uuidv4()}
                            >{child}</Col>
                        );
                    })
                        : children}
                </Row>
                : children}
            {/* {breadcrumb ?
                <Row className='g-0'>
                    <_Breadcrumb
                        nav={{
                            title: 'Permessi',
                            navItems: [
                                { value: 'Home', url: '/' },
                                { value: 'Preferenze', url: '/settings' },
                                { value: 'Utenti e ruoli', url: null },
                            ],
                        }}
                    />
                </Row>
                : ''} */}
        </Container>
    );
};

/**
 * Ritorna una Row con una Card già formattata
 * @param {*} title Il titolo della card
 * @param {*} link Se deve avere un link
 * @param {*} button Il figlio del button del link da seguire
 * @param {*} children Il componente figlio
 * @param {*} footer Il componente Card.Footer
 * @param {*} cardClasses Aggiunge le classi alla Card
 * @param {*} cardBodyClasses Aggiunge le classi alla Card.Body
 *
 * Se non c'é presente il titolo non ritorna la Card.Header
 * @returns
 */
export const SettingsCard = ({
    title,
    link,
    button,
    children,
    footer,
    submitButtonText,
    cardClasses,
    cardBodyClasses,
    asyncAction,
    col,
}) => {

    const navigate = useNavigate();

    const { promiseInProgress } = usePromiseTracker();

    const [hasPermissions, setPermissions] = useState(false);

    const localPermissions = getLocationPermissions();
    const userPermissions = useUserHasPermission(localPermissions);

    useEffect(() => {
        setPermissions(userPermissions);

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [userPermissions]);

    return (
        <div className={`mx-0 ${col ? 'col' : 'row'}`}>
            <Card className={`p-0 ${cardClasses}`}>
                {title ?
                    <Card.Header className='d-flex justify-content-between'>
                        <h4 className='mb-0'>{title}</h4>
                        {link && hasPermissions ?
                            <div>
                                <Button
                                    size='sm'
                                    variant='outline-primary'
                                    onClick={() => typeof link === 'string' ? navigate(link) : link()}
                                >{button}</Button>
                            </div>
                            : ''}
                    </Card.Header>
                    : ''}
                <Card.Body className={`p-0 ${cardBodyClasses}`}>
                    {children}
                </Card.Body>
                {footer ?
                    <Card.Footer className='bg-transparent border-0 p-3'>
                        <Col className='mx-3'>
                            <Form.Group as={Row} className='mb-3 g-0'>
                                <Col sm={2} md={4}></Col>
                                <Col>
                                    <Button
                                        type='submit'
                                        variant='outline-primary'
                                        disabled={asyncAction && promiseInProgress}
                                    >
                                        {asyncAction && promiseInProgress ?
                                            <Spinner
                                                as='span'
                                                animation='grow'
                                                size='sm'
                                                role='status'
                                                aria-hidden='true'
                                                className='me-2'
                                            /> : ''}
                                        {submitButtonText ? submitButtonText : 'Applica modifiche'}
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Card.Footer>
                    : ''}
            </Card>
        </div>
    );
};

const SettingsLayoutContainer = ({
    children,
}) => {

    /** They may will implemented later */
    // const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const isBadSoftUser = useBadSoftUser();
    const timeReportEnabled = useBoolSetting('timeReportEnabled');

    const { collapseSidebar, collapsed } = useProSidebar();

    const location = useLocation();

    /** General settings */
    const canViewKeychainEntries = useUserHasPermission('canViewKeychainEntries');
    const canEditKeychainEntries = useUserHasPermission([
        '63405b37f5cf860387873209',
        '63c9855996ad2dcd1ff5e7d5'
    ]);
    const canEditCmsSettings = useUserHasPermission('canEditCmsSettings');

    /** Admin parts */
    const canEditTimeReport = useUserHasPermission('canEditTimeReport', undefined, true);
    const canInteractWithTimeReport = useUserHasPermission('canInteractWithTimeReport');
    const canViewAdminParts =
        canEditTimeReport ||
        canInteractWithTimeReport;

    useEffect(() => {
        if (window.screen.width < 1000 && !collapsed) {
            collapseSidebar();
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [window.screen.width]);


    return (
        <Container fluid className='px-0'>
            <Navbar />
            <div className='h-100 settings-main-container g-0' style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
                <Sidebar
                    className='h-100 p-0'
                    backgroundColor='rgb(222, 222, 222, 0.7)'
                    defaultCollapsed={collapsed}
                >
                    <div className='ps-4 pt-3'>
                        <span className='text-muted text-uppercase fw-bold small'>Generali</span>
                    </div>
                    <Menu closeOnClick={collapsed}>
                        <MenuItem icon={collapsed && location.pathname === '/settings' ? <IoSettings color='green' /> : <IoSettingsOutline />} routerLink={<Link to='/settings' />} active={!collapsed && location.pathname === '/settings'}>Informazioni</MenuItem>
                        <SubMenu icon={collapsed && location.pathname.startsWith('/settings/users') ? <IoPeople color='green' /> : <IoPeopleOutline />} label='Utenti e ruoli' defaultOpen={!collapsed && location.pathname.startsWith('/settings/users')}>
                            <MenuItem routerLink={<Link to='/settings/users' />} active={!collapsed && location.pathname === '/settings/users'}>Utenti</MenuItem>
                            <MenuItem routerLink={<Link to='/settings/users/roles' />} active={!collapsed && location.pathname === '/settings/users/roles'}>Ruoli</MenuItem>
                            <MenuItem routerLink={<Link to='/settings/users/permissions' />} active={!collapsed && location.pathname === '/settings/users/permissions'}>Permessi</MenuItem>
                        </SubMenu>
                        {canViewKeychainEntries || canEditKeychainEntries ?
                            <Menu closeOnClick={collapsed}>
                                <MenuItem icon={collapsed && location.pathname.startsWith('/settings/keychain') ? <IoKey color='green' /> : <IoKeyOutline />} routerLink={<Link to='/settings/keychain' />} active={!collapsed && location.pathname === '/settings/keychain'}>Gestione chiavi</MenuItem>
                            </Menu> : ''}
                        {canEditCmsSettings ?
                            <SubMenu icon={collapsed && location.pathname.startsWith('/settings/cms') ? <IoServer color='green' /> : <IoServerOutline />} label='Editor CMS' defaultOpen={!collapsed && location.pathname.startsWith('/settings/cms')}>
                                <MenuItem routerLink={<Link to='/settings/cms/module-options' />} active={!collapsed && location.pathname === '/settings/cms/module-options'}>Impostazioni moduli</MenuItem>
                            </SubMenu>
                            : ''}
                    </Menu>
                    {canViewAdminParts && (timeReportEnabled) ?
                        <div className='ps-4 pt-3'>
                            <span className='text-muted text-uppercase fw-bold small'>Admin</span>
                        </div> : ''}
                    {timeReportEnabled && (canEditTimeReport || canInteractWithTimeReport) ?
                        <Menu closeOnClick={collapsed}>
                            <MenuItem icon={collapsed && location.pathname === '/settings/timereport' ? <IoTime color='green' /> : <IoTimeOutline />} routerLink={<Link to='/settings/timereport' />} active={!collapsed && location.pathname === '/settings/timereport'}>BadSoft Time Report</MenuItem>
                        </Menu> : ''}

                    {isBadSoftUser ?
                        <>
                            <div className='ps-4 pt-3'>
                                <span className='text-muted text-uppercase fw-bold small'>BadSoft</span>
                            </div>
                            <Menu closeOnClick={collapsed}>
                                <MenuItem icon={collapsed && location.pathname === '/settings/badsoft' ? <GiBleedingEye color='red' /> : <GiBleedingEye />} routerLink={<Link to='/settings/badsoft' />} active={!collapsed && location.pathname === '/settings/badsoft'}>BadSoft Dashboard</MenuItem>
                            </Menu>
                        </>
                        : ''}
                </Sidebar>
                <Col className='settings-container'>
                    {children}
                </Col>
            </div>
        </Container>
    );
};

export const SettingsLayout = ({
    children,
}) => {

    return (
        <SettingsContextProvider>
            <SettingsLayoutContainer>
                {children}
            </SettingsLayoutContainer>
        </SettingsContextProvider>
    );
};

export default SettingsLayout;
