/**
 * The actions enum
 */
export enum UserContextAction {
    /**
     * Set the user
     */
    SetUser = 'SET_USER',

    /**
     * Destroy the user
     */
    DestroyUser = 'DESTROY_USER',
}