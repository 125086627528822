import React, { useState } from 'react';
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import { useSession } from '../auth/useSession';
// import { useUser } from '../auth/useUser';
import CreateTicketModal from '../views/BetaTesing/_CreateTicket';

// Contexts
import { useSession } from '../contexts/SessionContext';
import { useHasPermissions, UserEmail } from '../contexts/UserContext';

// Methods
import { Logout } from '../methods/AdminMethods';

// Icons
import { AiOutlinePoweroff } from 'react-icons/ai';
import { IoBug } from 'react-icons/io5';

// Styles
import '../styles/Navbar.css';

const _Navbar = () => {

	const { destroySession } = useSession();

	const email = UserEmail();

	const path = window.location.pathname;

	// React router DOM
	const navigate = useNavigate();

	// Session object
	// const { setSession } = useSession();

	const [showCreateTicket, setShowCreateTicket] = useState(false);

	const handleShowCreateTicket = () => setShowCreateTicket(true);
	const handleHideCreateTicket = () => setShowCreateTicket(false);

	const handleLogout = async (event) => {
		event.preventDefault();

		const result = await Logout();

		if (result?.Error) {
			console.log('Ooops...something went wrong');
			return;
		}

		destroySession();
		navigate('/login');
	};

	const cmsPageEnabled = useHasPermissions([
		'canViewCMSPages',
		'canEditAllCMSPages',
		'canEditOwnCMSPages',
		'canViewMedia',
		'canEditMedia',
	]);

	const cmsMediaEnabled = useHasPermissions([
		'canViewMedia',
		'canEditMedia',
	]);

	const canBetaEnabled = useHasPermissions([
		'canAddBetaUsers',
		'canViewJiraBetaIssues',
		'canInteractWithJiraBetaIssues',
	]);

	const canAccessToSettings = useHasPermissions('canAccessToSettings');

	return (
		<Navbar
			bg='light'
			variant='light'
			fixed='top'
			className='opacity-95'
			collapseOnSelect
			expand='lg'
		>
			<Container fluid>
				<Navbar.Brand href='/home'>
					<img
						alt='Garden Bürgi'
						src='/logo_navbar.png'
						className='d-inline-block align-top'
						height={30}
					/>
					<span
						className='align-bottom'
						style={{
							color: '#e30178',
							fontSize: 'medium',
							fontWeight: '700',
						}}
					>admin</span>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbar-nav" />
				<Navbar.Collapse id="navbar-nav">
					<Nav className='me-auto'>
						<Nav.Link href='/home' className={path === '/' || path === '/home' ? 'active' : ''}>Dashboard</Nav.Link>
						{cmsPageEnabled ?
							<NavDropdown title='Gestione Sito' id='cms-nav-dropdown' className={path.startsWith('/cms') ? 'active' : ''}>
								<NavDropdown.Item href='/cms' className={path === '/cms' ? 'active' : ''}>Pagine</NavDropdown.Item>
								{cmsMediaEnabled && <NavDropdown.Item href='/cms/media-management' className={path === '/cms/media-management' ? 'active' : ''}>Media</NavDropdown.Item>}
							</NavDropdown>
							: ''}
						{canAccessToSettings && <Nav.Link href='/settings' className={path.startsWith('/settings') ? 'active' : ''}>Preferenze</Nav.Link>}
					</Nav>
					<Navbar.Text className='me-1'>
						{email && <span className='me-2'>{email}</span>}
						<a href='#' onClick={handleLogout}>
							<AiOutlinePoweroff />
						</a>
					</Navbar.Text>
					{canBetaEnabled ?
						<Nav className='ms-3'>
							<Nav.Link href='/betatest' className={path.startsWith('/betatest') ? 'active' : ''} id='beta-button'>
								<IoBug fontSize='1.2rem' />
								<span className='ms-1'>Beta test</span>
							</Nav.Link>
							<Button
								variant='primary'
								onClick={handleShowCreateTicket}
							>Crea ticket</Button>
						</Nav> : ''}
				</Navbar.Collapse>
			</Container>
			<CreateTicketModal
				show={showCreateTicket}
				handleClose={handleHideCreateTicket}
			/>
		</Navbar>
	);
};

export default _Navbar;